import Enum from '@/utils/Enum';

const InvoiceTypeEnum = [
  {
    label: '增值税普通发票',
    name: '增值税普通发票',
    value: 'GENERAL',
  },
  {
    label: '增值税专用发票',
    name: '增值税专用发票',
    value: 'DEDICATED',
  },
  {
    label: '增值税电子普通发票',
    name: '增值税电子普通发票',
    value: 'ELECTRONIC_GENERAL',
  },
  {
    label: '增值税电子专用发票',
    name: '增值税电子专用发票',
    value: 'ELECTRONIC_DEDICATED',
  },
];

export default new Enum(InvoiceTypeEnum);
export { InvoiceTypeEnum };
