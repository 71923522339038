<template>
  <div class="dialog-wrapper">
    <div class="step">
      <span :style="{ color: step === 'first' || step === 'second' ? '#237FFA' : '' }">
        <i class="el-icon-upload" />
        导入文件
      </span>
      <i :style="{ color: step === 'first' || step === 'second' ? '#237FFA' : '' }" class="el-icon-arrow-right" />
      <span :style="{ color: step === 'second' ? '#237FFA' : '' }">
        <i class="el-icon-s-order" />
        数据校验
      </span>
      <i :style="{ color: step === 'second' ? '#237FFA' : '' }" class="el-icon-arrow-right" />
      <span>
        <i class="el-icon-s-unfold" />
        导入系统
      </span>
    </div>
    <div class="wrapper">
      <span class="title">一、请按照数据模板的格式准备要导入的数据</span>
      <a class="download" @click="downloadModel">点击下载数据模板</a>
      <br />
      <span class="desc">导入数据请勿超过{{ limit }}条，超过{{ limit }}条数据请分批导入。</span>
    </div>
    <div class="wrapper">
      <div class="subWrapper">
        <span class="title">二、请选择要导入的文件，进行数据校验</span>
        <el-upload :action="uploadDomain" :disabled="loading" :auto-upload="true" :show-file-list="false"
          :before-upload="handleExcel" :accept="accept">
          <a :class="['iconWrapper', loading ? 'loading' : '']">
            <i :class="loading ? 'el-icon-loading' : 'el-icon-upload2'" />
            导入文件
          </a>
        </el-upload>
        <a v-if="total" :class="['iconWrapper', checkLoading ? 'loading' : '']" @click="checkData">
          <i :class="checkLoading ? 'el-icon-loading' : 'el-icon-files'" />
          数据校验
        </a>
        <div v-if="total" class="process">
          <span>校验完成：{{ successNum }}条/{{ total }}条</span>
          <el-progress :percentage="percent" />
        </div>
      </div>
      <el-alert v-if="total && successNum === total" type="success" title="数据校验成功，可直接导入系统" show-icon />
      <el-alert v-if="errorNum" :title="`数据校验结果：当前有${errorNum}条数据不符合系统规范，请先调整红色标示内容后再进行导入`" show-icon />
      <el-alert v-if="checkInfo && !checkInfo.status" :title="checkInfo.message" type="error" :closable="false"
        show-icon></el-alert>
    </div>
    <div class="choices">
      <div>
        <el-button type="primary" @click="newData">新增行</el-button>
        <el-button type="primary" :disabled="!selectedKeys.length" @click="batchAddDevice">批量录入</el-button>
        <el-popconfirm :disabled="!selectedKeys.length" confirm-button-text="确认" cancel-button-text="取消" title="确认删除？"
          @confirm="handleBatchDelete">
          <el-button slot="reference" :disabled="!selectedKeys.length">批量删除</el-button>
        </el-popconfirm>
        <el-popconfirm :disabled="!selectedKeys.length" confirm-button-text="确认" cancel-button-text="取消" title="确认清空？"
          @confirm="handleEmpty">
          <el-button slot="reference" :disabled="!selectedKeys.length">清空</el-button>
        </el-popconfirm>
      </div>
      <div>
        <span style="margin-right: 2em">
          共&ensp;
          <span style="color: #237ffa">{{ total }}</span>
          &ensp;条
        </span>
        <el-checkbox v-model="onlyError">仅显示校验错误数据</el-checkbox>
      </div>
    </div>
    <el-form class="el-form-wrapper" v-loading="loading" element-loading-text="数据导入中" ref="excel" :model="excelForm">
      <el-table ref="table" border :data="excelForm.excelData" header-row-class-name="table-label"
        @selection-change="handleChange">
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column v-for="e in config" :key="e.key" :prop="e.key" :width="e.width" :label="e.title"
          :show-overflow-tooltip="e.ellipsis">
          <template #header>
            <span v-if="e.rules || e.setRules"
              style="color: #F56C6C;margin-right: 4px;font-family: SimSun, sans-serif;">*</span>{{ e.title }}
            <el-tooltip v-if="e.tooltip" popper-class="quotePriceDialog__tooltip" effect="dark"
              :content="e.tooltip.content" placement="top">
              <i class="el-icon-warning"></i>
            </el-tooltip>
          </template>
          <template slot-scope="{row, $index: index}">
            <template v-if="e._type === 'text'">{{ row[e.key] }}</template>
            <el-form-item :prop="'excelData.' + index + '.' + e.key"
              :rules="e.setRules ? e.setRules(row) : e.rules || []">
              <div style="display: flex; align-items: center">
                <template v-if="e._type === 'input'">
                  <el-input v-if="!e.isText(row)" v-model="row[e.key]"
                    :placeholder="`请输入${e.title}`" :style="{ width: e.inputWidth }" clearable />
                  <template v-else>{{ row[e.key] || '--' }}</template>
                </template>
                <el-date-picker v-if="e._type === 'date'" v-model="row[e.key]" value-format="timestamp"
                  :placeholder="`请输入${e.title}`" clearable></el-date-picker>
                <template v-if="e._type === 'number'">
                  <el-input-number v-if="!e.isText(row)" class="number-input" v-model="row[e.key]" :min="e.min"
                    :step="e.step" :precision="e.precision" :placeholder="`请输入${e.title}`" clearable
                    @change="() => e.handleChange(row)" />
                  <template v-else>{{ row[e.key] || '--' }}</template>
                </template>
                <template v-if="e._type === 'select'">
                  <el-select v-if="!e.isText(row)" v-model="row[e.key]"
                    :placeholder="`请选择${e.title}`" @change="v => e.handleChange(v, row)" clearable>
                    <el-option v-for="opt in e._options" :key="opt.value" :label="opt.label" :value="opt.value">
                    </el-option>
                  </el-select>
                  <template v-else>{{ row[e.key] || '--' }}</template>
                </template>
                <template v-if="e.subs">
                  <div v-for="child in e.subs" :key="child.key" :style="{ width: child.width, marginLeft: '8px' }">
                    <el-select v-if="child._type === 'select'" v-model="row[child.key]"
                      :placeholder="`请选择${child.title}`" clearable>
                      <a-option v-for="opt in child._options" :key="opt.value" :label="opt.label" :value="opt.value">
                      </a-option>
                    </el-select>
                  </div>
                </template>
              </div>
            </el-form-item>
          </template>
        </el-table-column>>
        <el-table-column label="操作" fixed="right">
          <template slot-scope="scope">
            <el-popconfirm confirm-button-text="确认" cancel-button-text="取消" title="确认删除？"
              @confirm="handleDelete(scope.$index)">
              <a slot="reference" style="color: #f56c6c; cursor: pointer;">删除</a>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </el-form>
    <div class="button-group">
      <el-button @click="handleCancel">取消</el-button>
      <el-button type="primary" :disabled="!excelForm.excelData.length" @click="confirmExcelData">确定</el-button>
    </div>
    <el-dialog width="430px" :append-to-body="true" :visible.sync="isBatchImport" title="批量录入"
      :close-on-click-modal="false" :destroy-on-close="true">
      <batch-import :config="editConfig" @cancel="() => (isBatchImport = false)"
        @confirm="handleBatchImportConfirm"></batch-import>
    </el-dialog>
  </div>
</template>

<script>
import excel from '@/utils/excel.js';
import _ from 'lodash';
import dayjs from 'dayjs';
import BatchImport from '@/components/eleBatchImport/index.vue';

export default {
  name: 'ExcelHandle',
  props: {
    limit: {
      type: Number,
      default: 300,
    },
    config: {
      type: Array,
      default: () => [],
    },
    downloadUrl: {
      type: Object,
      default: () => ({}),
    },
    beforeCheck: {
      type: Function,
      default: () => (new Promise(resolve => resolve(
        {
          status: true,
          message: '',
          data: [],
        },
      ))),
    },
    handleDownloadData: {
      type: Function,
      default: null,
    },
  },
  components: {
    BatchImport,
  },
  computed: {
    editConfig () {
      return this.config.filter(i => i._type !== 'text');
    },
    editKeys () {
      const editKeys = [];

      this.editConfig.forEach(c => {
        editKeys.push({ key: c.key, type: c._type, default: c.default });

        if (c.subs) {
          c.subs.forEach(child => {
            editKeys.push({ key: child.key, type: child._type, default: child.default });
          });
        }
      });
      return editKeys;
    },
    total () {
      return this.excelForm.excelData.length;
    },
    requiredKeys () {
      return this.config.filter(v => v.rules || v.setRules);
    },
  },
  watch: {
    onlyError (newV) {
      if (newV) {
        this.excelForm.excelData = this.tableData.filter(e => {
          return !this.validate(e);
        });
      } else {
        this.excelForm.excelData = this.tableData;
      }
    },
  },
  data () {
    return {
      step: 'first',
      errorNum: 0,
      successNum: 0,
      tableData: [],
      excelForm: {
        excelData: [],
      },
      accept: '.xls,.xlsx',
      percent: 0,
      checkLoading: false,
      onlyError: false,
      selectedKeys: [],
      loading: false,
      confirmExcelData: _.throttle(this.handleConfirm, 50),
      isBatchImport: false,
      uploadDomain: 'https://upload.qiniup.com',
      checkInfo: null,
    };
  },
  methods: {
    downloadModel () {
      var xhr = new XMLHttpRequest();
      xhr.open('get', this.downloadUrl.url);
      xhr.responseType = 'blob';
      xhr.onload = () => {
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(xhr.response);
        link.target = '_blank';
        link.download = this.downloadUrl.name;
        link.click();
        link.remove();
      };
      xhr.send();
    },
    formatDate (numb) {
      let getDay = numb - 1;
      let t = Math.round((getDay - Math.floor(getDay)) * 24 * 60 * 60);

      return new Date(1900, 0, getDay, 0, 0, t);
    },
    handleExcel (file) {
      try {
        this.loading = true;

        let reader = new FileReader();

        reader.readAsArrayBuffer(file);

        reader.onload = e => {
          const data = e.target.result;
          const titles = [];
          let isError = false;

          const { header = [], results = [] } = excel.read(data, { type: 'array' });

          // excel的第一行会被解析为表头，过滤超出列数的单元格描述
          header.splice(this.editConfig.length);

          this.editConfig.forEach(con => {
            titles.push(con.title);

            if (con.subs) {
              con.subs.forEach(sub => {
                titles.push(sub.title);
              });
            }
          });

          for (const k in titles) {
            if (header[k].replace(/\*/g, '') !== titles[k]) {
              isError = true;
              break;
            }
          }

          if (isError) {
            this.$message.error('上传模板错误！');
            this.loading = false;
            return false;
          }

          if (!results.length) {
            this.$message.warning('模板无数据！');
            this.loading = false;
            return false;
          }

          const tableData = results.map(r => {
            const o = {
              _id: _.uniqueId(),
            };

            header.forEach((k, index) => {
              if (this.editKeys[index].type === 'date') {
                o[this.editKeys[index].key] = r[k] ? dayjs(this.formatDate(r[k])).valueOf() : '';
              } else {
                o[this.editKeys[index].key] = r[k];
              }
            });

            return o;
          });

          if (this.handleDownloadData) {
            this.handleDownloadData(tableData);
          }

          this.tableData = [...tableData, ...this.tableData];

          if (this.onlyError) {
            this.excelForm.excelData = this.tableData.filter(e => {
              return !this.validate(e);
            });
          } else {
            this.excelForm.excelData = this.tableData;
          }

          this.errorNum = 0;
          this.successNum = 0;
          this.percent = 0;

          this.loading = false;
        };
      } catch (error) {
        this.loading = false;
      }
    },
    handleDelete (index) {
      const _id = this.excelForm.excelData[index]._id;

      this.excelForm.excelData.splice(index, 1);

      const tIndex = this.tableData.findIndex(t => t._id === _id);

      if (tIndex !== -1) {
        this.tableData.splice(tIndex, 1);
      }

      if (this.selectedKeys.includes(_id)) {
        const index = this.selectedKeys.findIndex(id => id === _id);

        this.selectedKeys.splice(index, 1);
      }

      this.$nextTick(() => {
        try {
          this.$refs.excel.validate();
        } catch (error) { }
      });
    },
    validate (v) {
      let ret = true;

      this.requiredKeys.forEach(k => {
        let isRequired = true;

        if (k.setRules) {
          const res = k.setRules(v);
          isRequired = res[0].required;
        }

        if (isRequired) {
          if (k._type === 'number' && v[k.key] !== undefined && v[k.key] !== '' && v[k.key] !== null) {
            ret &&= (parseFloat(v[k.key]) && Number(v[k.key]) && Number(v[k.key]) > 0);
          } else if (k.rules && k.rules[0].isOk) {
            ret &&= k.rules[0].isOk(v);
          } else {
            ret &&= !!v[k.key];
          }
        }
      });

      return ret;
    },
    updateTable () {
      const vals = {};
      this.excelForm.excelData.forEach((e, index) => {
        vals[e._id] = index;
      });

      this.tableData.forEach(t => {
        if (vals[t._id] || vals[t._id] === 0) {
          t = Object.assign(t, this.excelForm.excelData[vals[t._id]]);
        }
      });
    },
    async checkData () {
      if (this.checkLoading) {
        return false;
      }

      this.checkLoading = true;

      this.checkInfo = await this.beforeCheck(this.excelForm.excelData, this.updateTable);

      try {
        let errorNum = 0;
        let successNum = 0;

        this.excelForm.excelData.forEach((e, i) => {
          this.validate(e) ? successNum++ : errorNum++;

          this.percent = Math.ceil(((i + 1) / this.total) * 100);
        });

        this.errorNum = errorNum;
        this.successNum = successNum;

        this.$refs.excel.validate(valid => {
          this.checkLoading = false;

          if (!valid) {
            return false;
          }

          this.step = 'second';
        });
      } catch (e) {
        this.checkLoading = false;
      }
    },
    handleCancel () {
      this.$emit('cancel');
    },
    async handleConfirm () {
      this.checkInfo = await this.beforeCheck(this.excelForm.excelData, this.updateTable);

      this.$refs.excel.validate(valid => {
        if (!valid) {
          return false;
        }

        this.$emit('confirm', this.tableData);
      });
    },
    handleChange (selections) {
      this.selectedKeys = selections.map(i => i._id);
    },
    handleBatchDelete () {
      const filteredExcelData = this.excelForm.excelData.filter(i => !this.selectedKeys.includes(i._id));

      this.excelForm.excelData = filteredExcelData;

      const filteredTableData = this.tableData.filter(i => !this.selectedKeys.includes(i._id));
      this.tableData = filteredTableData;

      this.selectedKeys = [];

      this.$nextTick(() => {
        try {
          this.$refs.excel.validate();
        } catch (error) { }
      });
    },
    handleEmpty () {
      this.tableData = [];
      this.excelForm.excelData = [];
      this.selectedKeys = [];
    },
    newData () {
      const data = {
        _id: _.uniqueId(),
      };

      this.editKeys.forEach(k => {
        data[k.key] = k.default;
      });

      this.excelForm.excelData.push(data);

      if (this.tableData.findIndex(t => t._id === data._id) === -1) {
        this.tableData.push(data);
      }
    },
    batchAddDevice () {
      this.isBatchImport = true;
    },
    handleBatchImportConfirm (v) {
      const selectedData = this.excelForm.excelData.filter(d => this.selectedKeys.includes(d._id));

      for (const k in v) {
        selectedData.forEach(selected => {
          selected[k] = v[k];
        });
      }

      this.isBatchImport = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-wrapper {
  position: relative;
  height: 763px;
  display: flex;
  flex-direction: column;
}

.step {
  width: 100%;
  background: #f2f4f7;
  height: 46px;
  line-height: 46px;

  span {
    display: inline-block;
    width: 30%;
    text-align: center;
  }
}

.button-group {
  width: 100%;
  padding: 10px 16px 3px;
  text-align: right;
  background: transparent;
  border-top: 1px solid #e8e8e8;
  border-radius: 0 0 4px 4px;
}

.title {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  margin-right: 0.5em;
}

.wrapper {
  margin-top: 12px;

  .download {
    color: #1890ff;
    text-decoration: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    transition: color .3s;
  }

  .subWrapper {
    display: flex;
    align-items: center;
    line-height: 16px;
  }

  .desc {
    display: inline-block;
    text-indent: 2em;
    color: #929aa6;
  }

  .process {
    display: inline-block;
    width: 9.5em;

    span {
      font-size: 10px;
      color: #929aa6;
    }
  }

  .iconWrapper {
    display: inline-block;
    padding: 4px;
    background: #e2f3fe;
    border-radius: 2px;
    font-size: 12px;
    color: #237ffa;
    margin-right: 1em;
    cursor: pointer;

    &.loading {
      opacity: 0.6;
    }

    &:hover {
      opacity: 0.8;
    }
  }
}

.choices {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1em;
  margin-bottom: 1em;

  button {
    margin-right: 1em;
  }
}

.el-form-wrapper {
  flex: 1;
  overflow: auto;

  /deep/ .el-form-item {
    margin-bottom: 0;
  }

  /deep/ .table-label {
    color: rgba(0, 0, 0, 0.85);
    font-family: Helvetica, "Hiragino Sans GB", "Microsoft Yahei", 微软雅黑, Arial, sans-serif, PingFangSC-Medium, "PingFang SC";
    font-size: 14px;
    font-weight: bold;
  }
}

/deep/ .el-progress__text {
  font-size: 12px !important;
}

/deep/ .el-form-item.is-error .el-input__inner {
  border-color: #F56C6C !important;
}

.number-input {

  /deep/ .el-input-number__decrease,
  /deep/ .el-input-number__increase {
    display: none;
  }

  /deep/ .el-input__inner {
    padding: 0 15px;
    text-align: left;
  }
}
</style>
