<template>
  <div class="inquiryform-handle">
    <div class="content">
      <div class="content__main">
        <div class="content__main-flag">
          <img :src="flagSvg">
        </div>
        <div class="content__main-header">
          <div class="content__main-header-order">询单号：{{ detail.code }}</div>
          <div class="content__main-header-name">
            {{ detail.inquirePriceParty }}_{{ detail.businessTypeDesc }}_{{ detail.code }}
            <span v-if="detail.inquireTenantStatusDesc" :class="['c_tag', detail.inquireTenantStatus ? detail.inquireTenantStatus.toLowerCase() : '']">
              {{ detail.inquireTenantStatusDesc }}
            </span>
            <span v-if="detail.inquireTenantStatus === 'CLOSED'"
              :class="['c_reason', detail.inquireTenantStatus ? detail.inquireTenantStatus.toLowerCase() : '']">
              <i class="el-icon-warning-outline"></i>关单原因：{{ detail.closeReason }}
            </span>
          </div>
        </div>
        <el-image v-if="detail.winTheBidFlag" class="tupian" :src="zhongbiao"></el-image>
        <el-image v-else-if="detail.enteredFlag" class="tupian" :src="ruwei"></el-image>
      </div>
      <el-tabs v-model="activeName">
        <el-tab-pane label="询单信息" name="order">
          <component :is="orderComponent" class="pane" :info="detail" :isHandle="true"></component>
        </el-tab-pane>
        <el-tab-pane label="报价信息" name="price">
          <component ref="price" :is="priceComponent" class="pane" :id="id" :isHandle="true"  :contactInfo="contactInfo"></component>
        </el-tab-pane>
        <el-tab-pane label="履约信息" name="contract">
          <Contract class="pane"></Contract>
        </el-tab-pane>
        <el-tab-pane label="日志动态" name="log">
          <Log ref="log" class="pane" :id="id"></Log>
        </el-tab-pane>
      </el-tabs>
    </div>
    <div class="button-wrapper">
      <el-button @click="handleBack">返回</el-button>
      <el-button type="danger" v-if="['WAIT_TASK_ORDER', 'WAIT_QUOTE_PRICE'].includes(detail.inquireTenantStatus) || (['NEGOTIATING'].includes(detail.inquireTenantStatus) && (['UPDATE', 'ROLLBACK'].includes(detail.negotiatingType)))" plain @click="() => openDialog('reject')">拒单</el-button>
      <!--本期不做转单-->
      <!--<el-button type="primary" v-if="['WAIT_TASK_ORDER', 'WAIT_QUOTE_PRICE'].includes(detail.inquireTenantStatus) || (['NEGOTIATING'].includes(detail.inquireTenantStatus) && (['UPDATE', 'ROLLBACK'].includes(detail.negotiatingType)))" @click="() => openDialog('transfer')">转单</el-button>-->
      <el-button type="primary" v-if="['WAIT_TASK_ORDER'].includes(detail.inquireTenantStatus)"  @click="handleTask">接单</el-button>
      <el-button type="primary" v-if="['NEGOTIATING'].includes(detail.inquireTenantStatus) && (['UPDATE'].includes(detail.negotiatingType))" @click="handleConfirmQuotePrice">确认报价</el-button>
      <el-button type="primary" v-if="['WAIT_TASK_ORDER', 'WAIT_QUOTE_PRICE'].includes(detail.inquireTenantStatus) || (['NEGOTIATING'].includes(detail.inquireTenantStatus) && (['ROLLBACK'].includes(detail.negotiatingType)))" @click="handleQuotePrice">报价</el-button>
    </div>
    <el-dialog :title="title" :visible.sync="dialogVisible" width="30%">
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item v-if="handleType === 'reject'" label="拒单原因" :prop="rejectType">
          <el-select v-model="form.rejectType" placeholder="请选择拒单原因" clearable>
            <el-option v-for="item in rejectOptions" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleConfirm">确 定</el-button>
      </span>
    </el-dialog>
    <component ref="quotePrice" :is="quotePriceComponent" :id="id" :status="detail.inquireTenantStatus" :dialogVisible.sync="quotePriceDialogVisible"
      :sparePartList="detail.sparePartList || []" @callback="handleCallback"></component>
  </div>
</template>
<script>
import MaintainOrder from './detail/maintain/order.vue';
import MaintainPrice from './detail/maintain/price.vue';
import MaintainQuotePriceDialog from './detail/maintain/quotePriceDialog.vue';
import SparepartOrder from './detail/sparepart/order.vue';
import SparepartPrice from './detail/sparepart/price.vue';
import SparepartQuotePriceDialog from './detail/sparepart/quotePriceDialog.vue';
import Contract from './detail/contract.vue';
import Log from './detail/log.vue';
import { inquirePriceDetail, inquirePriceReject, inquirePriceTransfer, inquirePriceTaskOrder, inquireQuotePriceConfirm, inquireQuotePriceNewestDetail } from '@/api/inquiryForm.js';

const componentMap = {
  MAINTAIN: {
    orderComponent: MaintainOrder,
    priceComponent: MaintainPrice,
    quotePriceComponent: MaintainQuotePriceDialog,
  },
  SPARE_PART: {
    orderComponent: SparepartOrder,
    priceComponent: SparepartPrice,
    quotePriceComponent: SparepartQuotePriceDialog,
  },
};

export default {
  props: {
    id: {
      type: [Number, String],
      default: '',
    },
    type: {
      type: String,
      required: true,
    },
  },
  components: {
    MaintainOrder,
    MaintainPrice,
    MaintainQuotePriceDialog,
    SparepartOrder,
    SparepartPrice,
    SparepartQuotePriceDialog,
    Contract,
    Log,
  },
  computed: {
    orderComponent () {
      return componentMap[this.type].orderComponent;
    },
    priceComponent () {
      return componentMap[this.type].priceComponent;
    },
    quotePriceComponent () {
      return componentMap[this.type].quotePriceComponent;
    },
  },
  data () {
    return {
      flagSvg: require('@/assets/document_checked.svg'),
      ruwei: require('@/assets/ruwei.png'),
      zhongbiao: require('@/assets/zhongbiao.png'),
      activeName: 'order',
      detail: {},
      form: {},
      dialogVisible: false,
      quotePriceDialogVisible: false,
      rules: {
        rejectType: [
          { required: true, message: '请选择拒单原因', trigger: 'change' },
        ],
      },
      rejectOptions: [
        {
          label: '无法及时交付',
          value: 1,
        },
        {
          label: '无货/无法服务',
          value: 2,
        },
        {
          label: '单据错误',
          value: 3,
        },
        {
          label: '其它',
          value: 4,
        },
      ],
      title: '',
      handleType: '',
      contactInfo: {
        name: '',
        phone: '',
      },
    };
  },
  methods: {
    handleCallback () {
      this.getDetail();
      this.$refs.price.getDetail();
      this.$refs.log.init();
    },
    getDetail () {
      inquirePriceDetail({
        id: this.id,
      }).then(({ body }) => {
        this.detail = body || {};
        this.contactInfo = {
          name: body.createUserName,
          phone: body.createUserPhone,
        };
      });
    },
    openDialog (type) {
      if (type === 'reject') {
        this.title = '拒单';
      }

      this.handleType = type;
      this.form = this.$options.data().form;
      this.dialogVisible = true;
    },
    handleBack () {
      this.$router.back();
    },
    handleConfirm () {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.handleType === 'reject') {
            this.handleReject();
          }
        }
      });
    },
    async handleReject () {
      await inquirePriceReject({
        id: this.id,
        rejectType: this.form.rejectType,
      });

      this.$message.success('拒单成功');
      this.getDetail();
    },
    handleTransfer () {
      inquirePriceTransfer();
    },
    async handleTask () {
      await inquirePriceTaskOrder({ id: this.id });

      this.$message.success('接单成功');
      this.getDetail();
    },
    handleQuotePrice () {
      this.quotePriceDialogVisible = true;

      this.$nextTick(() => {
        this.$refs.quotePrice.getDetail();
      });
    },
    async handleConfirmQuotePrice () {
      const { body } = await inquireQuotePriceNewestDetail({
        findDraftFlag: false,
        inquirePriceId: this.id,
      });

      await inquireQuotePriceConfirm({ id: body.id });

      this.$message.success('确认报价成功');
      this.getDetail();
    },
  },
  mounted () {
    this.getDetail();
  },
};
</script>

<style scoped lang="scss">
.inquiryform-handle {
  height: 100%;
  display: flex;
  flex-direction: column;

  .content__main {
    position: relative;
    flex: 1;
    display: flex;
    align-items: center;
    background-color: #fff;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    padding: 18px 18px 0;

    .content__main-header {
      margin-left: 12px;

      .content__main-header-order {
        height: 20px;
        line-height: 20px;
        font-size: 14px;
      }

      .content__main-header-name {
        height: 32px;
        line-height: 32px;
        font-size: 16px;
        font-weight: 600;

        .c_tag {
          padding: 4px 8px;
          border-radius: 4px;
          font-size: 12px;
        }

        .c_reason {
          font-size: 12px;
          color: #FAB007;
          margin-left: 8px;
        }

        .wait_task_order {
          background-color: #FEFAE0;
          color: #FAB007;
        }

        .wait_quote_price {
          background-color: #FEFAE0;
          color: #FAB007;
        }

        .quoted {
          background-color: #E5FEEB;
          color: #14CA64;
        }

        .negotiating {
          background-color: #E2F3FE;
          color: #237FFA;
        }

        .entered {
          background-color: #E5FEEB;
          color: #14CA64;
        }

        .rejected {
          background-color: #FEE9E5;
          color: #FF5959;
        }

        .closed {
          background-color: #F2F4F7;
          color: #1F2733;
        }
      }
    }

    .tupian {
      position: absolute;
      top: 20px;
      right: 60px;
      width: 56px;
      height: 56px;
    }
  }

  /deep/ .el-tabs__header {
    background-color: #fff;
    padding: 0 18px 0;
    margin: 0;
  }

  .pane {
    height: calc(100vh - 316px);
    overflow: auto;
  }

  .button-wrapper {
    margin-top: 8px;
    padding: 0 16px;
    height: 60px;
    background-color: #fff;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}
</style>
